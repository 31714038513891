import * as React from 'react';
import ModalComponent from '../../components/modal/modal.component';
import StartOffboardingFeature from '../../features/boarding/onboarding/flow/start-offboarding.feature';
import useModal from '../../hooks/useModal';
import DashboardLayout from '../../layouts/DashboardLayout';
import { getEmployeeById, removeEmployee } from '../../services/user.service';
import { convertDate } from '../../utils/date';
import './styles/employees.style.css';
import styled from "styled-components";
import {useEffect, useState} from "react";
import {getActions} from "../../services/actions.service";
import {getActionsByEmployeeId, removeAssignedTask} from "../../services/tasks.service";
import AssignEmployeeTaskFeature from "../../features/task/assigne-employee-task.feature";
import { useNavigate, useParams } from "react-router-dom";
import {toTitleCase} from "../../utils/title-case.util";
import { EmptyBox } from "@rocket-desert/shared-ui";
import CreateButton from '../../components/Buttons/CreateButton.component';
import { generateUniqueKey }  from '../../utils/uniqueKey';

const EmptyBoxWrapper = styled.div`
  margin-top: 20px;  
`;

const BasicInfo = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 20px;  
`;

const BoxTitle = styled.div`
  font-weight: bold;
`;
const BoxContent = styled.div`
  padding-top: 15px;
`;

const BoxItem = styled.div` 
  height: 60px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin: 5px;
  border: 1px solid #f0f0f0;
`;

const Actions = styled.div`
  display: flex;
 
  flex-direction: row;
  gap:5px;
`;

const TaskContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
`;

const AssignedTasksWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

const WorkflowTitle = styled.div`
display: flex;
font-weight: bold;
`;


const WorkflowTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top:20px;
`;

const AddTask = styled.div`
cursor: pointer;
`;

const WorkflowRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
`;


const WorkflowHead = styled.div`

  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: #f0f0f0;
  border: 1px solid #e0e0e0;
  padding: 15px;
`;

const TaskTitle = styled.div`
  width: 200px;
`;
const TaskStatus = styled.div`
  width: 100px;
`;
const TaskStartDate = styled.div`
  width: 200px;
`;
const TaskDueDate = styled.div`
  width: 200px;
`;
const TaskAssignee = styled.div`
  width: 200px;
`;

const TaskTitleHead = styled.div`
  width: 200px;
  font-weight: bold;
`;
const TaskStatusHead = styled.div`
  width: 100px;
  font-weight: bold;
`;
const TaskStartDateHead = styled.div`
  width: 200px;
  font-weight: bold;
`;
const TaskDueDateHead = styled.div`
  width: 200px;
  font-weight: bold;
`;
const TaskAssigneeHead = styled.div`
  width: 200px;
  font-weight: bold;
`;

const TaskActionHead = styled.div`
  width: 200px;
  font-weight: bold;
`;

const TaskAction = styled.div`
  width: 200px;
  color: #2e5599;
`;

const RemoveActive = styled.div`
  width: 200px;
  color: #2e5599;
`;

const RemoveDisabled = styled.div`
  width: 200px;
  color: gray;
`;

const EmployeeName = styled.div`
  font-size: 25px;
  margin-top:25px;
  margin-left: 8px;
  padding: 0;   
`;

const StatusContainer = styled.div<{status: string}>`
    border-radius: 4px;
    padding: 4px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: ${({ status }) => {
    switch (status) {
        case 'TODO':
            return '#b2bec3';
        case 'IN PROGRESS':
            return '#fdcb6e';
        case 'COMPLETED':
            return 'green';
        default:
            return '#00b894';
    }
}};
`;

interface Props {
    children?: React.ReactNode
}
const EmployeeDetailsPage: React.FunctionComponent<Props> = () => {
    const [currentEmployee, setCurrentEmployee] = React.useState<any>();
    const [workflowTasks, setWorkflowTasks] = useState<any[]>([]);
    const [actions, setActions] = React.useState<any[]>([]);
    const modal1 = useModal(false);
    const modal2 = useModal(false);
    let { employeeId } = useParams();
    const navigate = useNavigate();

    React.useEffect(() => {
        async function getActionsList() {
            const actions = await getActions();
            setActions(actions);
        }
        getActionsList();
    }, []);

    React.useEffect(() => {
        async function getActionsList() {
            await getActions();
        }
        getActionsList();
    }, []);

    useEffect(()=> {
        async function getWorkflowTasks() {
            const workflowTasks = await getActionsByEmployeeId(employeeId);
            setWorkflowTasks(workflowTasks.data)
        }
        getWorkflowTasks();
    },[employeeId]);

    React.useEffect(() => {
        async function getEmployeeId() {
            const employee = await getEmployeeById(employeeId);
            setCurrentEmployee(employee.data);
        }
        getEmployeeId();
    }, [employeeId]);

    async function removeUser(employeeId: string) {
        await removeEmployee(employeeId);
        navigate('/employees');
    }

    async function removeAssigned(taskId: string) {
        await removeAssignedTask(taskId);
    }

    return <>
        <DashboardLayout>
            <div className="breadcrumbs">
                <div className="breadcrum-gray">Dashboard</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breadcrum-gray">Employee Page</div>                
            </div>
            { currentEmployee ? <>
            <div className="controls-page">
               
                <EmployeeName>{currentEmployee.firstName}  {currentEmployee.lastName}</EmployeeName>

                <Actions>
                <CreateButton
                    textColor="#2a7aeb"
                    color="#ffffff"
                    border="1px solid #2a7aeb"
                    text="Delete"
                    onClick= {() =>removeUser(currentEmployee.id)} /> 
                  
                    <CreateButton
                    textColor="#ffffff"
                    color="#2a7aeb"
                    text="+ Start Offboarding"
                    onClick={() => {modal1.toggle()}} />
                </Actions>
                <ModalComponent
                    isShowing={modal1.isShowing}
                    hide={modal1.toggle}
                    title="Start Offboarding"
                    id = "start-offboarding"
                >
                    <StartOffboardingFeature employeeId={employeeId} />
                 </ModalComponent>

            </div>          

           
              <BasicInfo>             
              <BoxItem>
                  <BoxTitle>Email</BoxTitle>
                  <BoxContent>{ currentEmployee.email }</BoxContent>
              </BoxItem>
              <BoxItem>
                  <BoxTitle>Position</BoxTitle>
                  <BoxContent>{ currentEmployee.position.name }</BoxContent>
              </BoxItem>
              <BoxItem>
                  <BoxTitle>Create Date</BoxTitle>
                  <BoxContent>{ convertDate(currentEmployee.createdAt) }</BoxContent>
              </BoxItem>             
              </BasicInfo>              
                       

            <AssignedTasksWrapper>
                <TaskContainer>
                    <WorkflowTitle>Tasks History</WorkflowTitle>
                    <AddTask onClick={modal2.toggle}>+ Add Task</AddTask>
                </TaskContainer>
                <ModalComponent
                    id = "assign-task"
                    isShowing={modal2.isShowing}
                    hide={modal2.toggle}
                    title= "Assign Task"
                >
                <AssignEmployeeTaskFeature employeeId={ employeeId } actionsList={actions} />
                </ModalComponent>
                    {workflowTasks.length ?
                    <WorkflowTable>
                        <WorkflowHead>
                            <TaskTitleHead>Task name</TaskTitleHead>
                            <TaskStatusHead>Status</TaskStatusHead>
                            <TaskStartDateHead>Assigned Date</TaskStartDateHead>
                            <TaskDueDateHead>Due Date</TaskDueDateHead>
                            <TaskAssigneeHead>Assigned to</TaskAssigneeHead>
                            <TaskActionHead>Actions</TaskActionHead>
                        </WorkflowHead>
                        { workflowTasks ? workflowTasks.map((item)=> {

                            return <WorkflowRow key={generateUniqueKey()}>
                                <>
                                    <TaskTitle>{item.title}</TaskTitle>
                                    <TaskStatus>
                                        <StatusContainer status={item.taskStatus}>
                                            {toTitleCase(item.taskStatus)}
                                        </StatusContainer>
                                    </TaskStatus>
                                    <TaskStartDate>{convertDate(item.startDate)}</TaskStartDate>
                                    <TaskDueDate>{convertDate(item.dueDate)}</TaskDueDate>
                                    <TaskAssignee>{ item.assignee.firstName } {item.assignee.lastName}</TaskAssignee>
                                    <TaskAction>
                                        { item.taskStatus === 'COMPLETED' ?
                                            <RemoveDisabled></RemoveDisabled> : <RemoveActive onClick={ () => removeAssigned(item.id) }>remove</RemoveActive> }
                                    </TaskAction>
                                </></WorkflowRow>})  : null }
                    </WorkflowTable> : <EmptyBoxWrapper><EmptyBox message='There is no any tasks' /></EmptyBoxWrapper> }
            </AssignedTasksWrapper> </>
            : null }
        </DashboardLayout>
    </>
}

export default EmployeeDetailsPage;

import { useState } from "react";
import AssessmentsItem from "./assessment-item.feature";
import Button from "../../components/Buttons/Button.component";
import styled from "styled-components";
import LinkButton from "../../components/LinkButton/LinkButton.component";
import { updateAssessments } from "../../services/actions.service";
import {generateUniqueKey} from "../../utils/uniqueKey";

const ActionsItem = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-items: center;
  align-items: center;
`;

const AnswersForm = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const QuestionInput = styled.input`
  border: none;
  border-bottom: 1px solid #ccc;
  color: #555;
  box-sizing: border-box;
  font-size: 18px;
  padding: 15px 10px;
  width: 100%;
  height: 50px;
  outline: none;
  background-color: #e7e9eb;
`;

const AnswerInput = styled.input`
  border: none;
  border-bottom: 1px solid #ccc;
  color: #555;
  box-sizing: border-box;
  font-size: 14px;
  padding: 7px 5px;
  width: 50%;
  outline: none;
  background-color: #e7e9eb;
`;

const ActionsForm = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding-left: 0;
  margin-top: 25px;
`;

const AddForm = styled.div`
  margin-top: 25px;
`;

const FormItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  flex-wrap: nowrap;
  margin-top: 15px;
  justify-content: left;
  align-items: center;
`;

const TresholdInput = styled.input`
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ccc;
  color: #555;
  box-sizing: border-box;
  font-size: 14px;
  padding: 7px 5px;
  width: 50px;
  outline: none;
  text-align: center;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex-wrap: nowrap;
  margin-top: 15px;
  justify-content: left;
`;

interface Props {
  actionId: string;
  isActive: boolean;
  threshold: number;
  assessments: any;
}

const AssessmentsFeature: React.FunctionComponent<Props> = ({
                                                              actionId,
                                                              isActive,
                                                              threshold,
                                                              assessments = [], // Default value to ensure assessments is always an array
                                                            }) => {
  const [isAddNewMode, setIsAddNewMode] = useState(false);
  const [isActiveValue, setIsActiveValue] = useState(isActive);
  const [thresholdValue, setThresholdValue] = useState<number>(threshold);
  const [tempQuestionValue, setTempQuestionValue] = useState("");
  const [tempAnswerValues, setTempAnswerValues] = useState<string[]>([
    "",
    "",
    "",
    "",
  ]);
  const [tempCorrectAnswers, setTempCorrectAnswers] = useState<string[]>();

  const [tempAssesments, setTempAssesments] = useState(assessments);

  const onEdit = (assessment: any, index: number) => {
    const newArray = [...tempAssesments];
    newArray[index] = assessment;
    setTempAssesments(newArray);
  };
  const onRemove = (assessment: any, index: number) => {
    const newArray = tempAssesments.filter((_, ind) => ind !== index);
    setTempAssesments(newArray);
  };

  const onAdd = () => {
    setTempQuestionValue("");
    setTempAnswerValues(["", "", "", ""]);
    setTempCorrectAnswers([]);
    setIsAddNewMode(true);
  };

  const onSave = () => {
    updateAssessments(actionId, {
      isActive: isActiveValue,
      threshold: thresholdValue,
      assessments: tempAssesments,
    });
  };

  const handleAnswerInputChange = (index, value) => {
    const updatedValues = [...tempAnswerValues];
    updatedValues[index] = value;
    setTempAnswerValues(updatedValues);
  };

  const handleCorrectAnswerInputChange = (value) => {
    setTempCorrectAnswers([value]);
  };

  const handleQuestionInputChange = (value) => {
    setTempQuestionValue(value);
  };

  const handleThresholdChange = (value: string) => {
    setThresholdValue(Number(value));
  };

  const handleIsActiveChange = (value) => {
    setIsActiveValue(value);
  };

  const handleCancel = () => {
    setIsAddNewMode(false);
  };

  const handleAdd = () => {
    const updatedValues = [
      ...tempAssesments,
      {
        question: tempQuestionValue,
        answers: tempAnswerValues,
        correctAnswer: tempCorrectAnswers,
      },
    ];
    setTempAssesments(updatedValues);
    setIsAddNewMode(false);
  };

  return assessments && !assessments?.length ? (
      <span>Please wait, AI generates assessments...</span>
  ) : (
      <div>
        {!isAddNewMode ? (
            <Controls>
              <FormItem>
                <label>Threshold (btw 1 and 100)</label>
                <TresholdInput
                    value={thresholdValue}
                    onChange={(e) => handleThresholdChange(e.target.value)}
                />
              </FormItem>
              <FormItem>
                <label>Is active</label>
                <input
                    type="checkbox"
                    name="isActive"
                    value="Is active"
                    onChange={(e) => handleIsActiveChange(e.target.checked)}
                    checked={isActiveValue}
                />
              </FormItem>
              <ActionsItem>
                <Button text="Save" onClick={onSave} />
                <LinkButton text="Add new" onClick={onAdd} />
              </ActionsItem>
            </Controls>
        ) : null}
        {isAddNewMode ? (
            <AddForm>
              <QuestionInput
                  type="text"
                  value={tempQuestionValue}
                  placeholder="type your question"
                  onChange={(e) => handleQuestionInputChange(e.target.value)}
              />

              {tempAnswerValues.map((answer, index) => (
                  <AnswersForm key={index}>
                    <input
                        type="radio"
                        name="correct"
                        value={answer}
                        onChange={(e) => handleCorrectAnswerInputChange(e.target.value)}
                    />
                    <AnswerInput
                        type="text"
                        value={answer}
                        placeholder={`answer ${index + 1}`}
                        onChange={(e) => handleAnswerInputChange(index, e.target.value)}
                        required
                    />
                  </AnswersForm>
              ))}

              <ActionsForm>
                <LinkButton text="Add" onClick={handleAdd} />
                <LinkButton text="Cancel" onClick={handleCancel} />
              </ActionsForm>
            </AddForm>
        ) : null}
        {tempAssesments.map((item) => (
            <AssessmentsItem
                assessment={item}
                index={generateUniqueKey()}
                onEdit={onEdit}
                onRemove={onRemove}
                key={generateUniqueKey()}
            />
        ))}
      </div>
  );
};

export default AssessmentsFeature;
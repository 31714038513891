import * as React from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';
import './styles/catalog.style.css';
import styled from "styled-components";
import {getAssetsCatalog} from "../../services/catalog.service";
import {useEffect, useState} from "react";
import {convertDate} from "../../utils/date";
import CreateButtonComponent from "../../components/Buttons/CreateButton.component";
import useModal from "../../hooks/useModal";
import ModalComponent from "../../components/modal/modal.component";
import CreateCatalogFeature from "../../features/catalogs/create-catalog.feature";
import {getUsers} from "../../services/user.service";
import {transformAssetTypeString} from "../../utils/asset-type";
import {transformActiveStatusString} from "../../utils/active-status";
import {useNavigate} from "react-router-dom";
import {EmptyBox} from "@rocket-desert/shared-ui";
import SpinnerWrapper from "../../components/Spinner/SpinnerWrapper.component";

const CatalogPage: React.FunctionComponent = () => {
    const {isShowing, toggle} = useModal();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    /**
     * Handles navigation to a given URL.
     *
     * @param {string} url - The URL to navigate to.
     * @returns {Promise<void>} - A promise that resolves when navigation is complete.
     */
    const handleNavigate = async (url: string) => {
        navigate(url);
    }
    const fetchAssets = async () => {
        const catalogList = await getAssetsCatalog();
        setCatalogs(catalogList);
    }
    const [catalogs, setCatalogs] = useState<any>();
    const [users, setUsers] = useState<{id: number | string; firstName: string}[]>([]);
    useEffect(()=> {
        async function getLeaders() {
            const users = await getUsers();
            setUsers(users.users);
        }
        getLeaders();
    },[]);
    React.useEffect(() => {
        async function getAssets() {
            setIsLoading(true);
            const catalogList = await getAssetsCatalog();
            setCatalogs(catalogList);
            setIsLoading(false);
        }
        getAssets();
    },[]);
    return <>
        <DashboardLayout>
            <div className="breadcrumbs">
                <div className="breadcrum-gray">Dashboard</div>
                <div className="breadcrum-divider"> / </div>
                <div className="breakcrum-active"> Catalog </div>
            </div>

            {isLoading ? <SpinnerWrapper /> : <>
            <CatalogHeader>
                <Title>Assets Catalog</Title>
                <NewAsset>
                    <CreateButtonComponent textColor="#ffffff" color="#2a7aeb"  text="+ New asset" onClick={ toggle } />
                    <ModalComponent
                        isShowing={isShowing}
                        hide={toggle}
                        title="Create Asset"
                    >
                        {users.length? <CreateCatalogFeature fetchAssets={fetchAssets} users={users} title = 'Create Asset' /> : <EmptyBox message={'There is no any employee find who will  be assign for new asset'}></EmptyBox> }
                    </ModalComponent>
                </NewAsset>
            </CatalogHeader>
            { catalogs && catalogs.length ?
            <AssetContainer>
            <CatalogWrapper>
                <CatalogTable>
                    <CatalogThead>
                        <CatalogTheadTr>                        
                        <CatalogTh>Asset Name</CatalogTh>
                        <CatalogTh>Description</CatalogTh>
                        <CatalogTh>Serial Number</CatalogTh>
                        <CatalogTh>Status</CatalogTh>
                        <CatalogTh>Asset Type</CatalogTh>
                        <CatalogTh>Availability</CatalogTh>
                        <CatalogTh>Licence</CatalogTh>
                        <CatalogTh>Added At</CatalogTh>
                        <CatalogTh>Edit</CatalogTh>
                        </CatalogTheadTr>
                    </CatalogThead>
                    <CatalogTbody>
                        {catalogs ? catalogs.map((item)=> {
                            return (
                                <CatalogTr key={item.id} onClick={()=> handleNavigate(`/catalog/${item.id}`)}>                                  
                                    <CatalogTd><Bold>{item.name}</Bold></CatalogTd>
                                    <CatalogTd>{item.description}</CatalogTd>
                                    <CatalogTd>{item.code}</CatalogTd>
                                    <CatalogTd><StatusBlock color='#273c75'>{ transformActiveStatusString(item.status)}</StatusBlock></CatalogTd>
                                    <CatalogTd><StatusBlock color='#718093'>{ transformAssetTypeString(item.asset_type)}</StatusBlock></CatalogTd>
                                    <CatalogTd><StatusBlock color='#718093'>{item.availability}</StatusBlock></CatalogTd>
                                    <CatalogTd>{item.licence ? item.licence : '-'}</CatalogTd>
                                    <CatalogTd>{ convertDate(item.created_at)}</CatalogTd>
                                    <CatalogTd>Edit</CatalogTd>
                                </CatalogTr>
                            )
                        }) : null }

                    </CatalogTbody>
                </CatalogTable>
            </CatalogWrapper> </AssetContainer> : <EmptyBox message={`There is no any assets`} /> } </> }
        </DashboardLayout>
    </>
}

const NewAsset = styled.div``;
const AssetContainer = styled.div``;
const Title = styled.h1`
    font-size: 20px;
    padding-left: 0;
    margin-left: 0;
`;

const CatalogTheadTr = styled.tr`
    display: flex;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
`;

const CatalogHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 20px;
`;

const CatalogTbody = styled.tbody`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    color: #121111;
    padding: 10px;
`;

const CatalogTr = styled.tr`
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 50px;
    background-color: white;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
    align-items: center;
`;

const CatalogTd = styled.td`
    flex: 1;
    text-align: left;
    align-items: center;
`;

const CatalogWrapper = styled.div`
    display: flex;
    width: 100%;
    margin-top: 5px;
`;

const CatalogTable = styled.table`
    display: flex;
    width: 100%;
    flex-direction: column;
`;

const CatalogThead = styled.thead`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    height: 20px;
    color: #121111;
    background-color: #f0f0f0;
    border: 1px solid #e0e0e0;
    align-items: center;
`;

const CatalogTh = styled.th`
    flex: 1;
    text-align: left;
    font-size: 13px;
`;

const Bold = styled.span`
    font-weight: normal;
    color: #121111;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 16px;
`;

const StatusBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 30px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: lighter;
    text-align: center;
    cursor: pointer;;
    color: white;
    background-color: ${props => props.color};
`;

export default CatalogPage;
